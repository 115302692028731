


















import { defineComponent, getCurrentInstance, Ref } from '@vue/composition-api'
import { ApolloError, NetworkStatus } from 'apollo-client'

import SettingImportModal from '@/components/templates/h/SettingImportModal.vue'
import PageView from '@/components/templates/h/yosan-haibun/weekly-daily-budget-conf/PageView.vue'
import {
  WeeklyDailyBudgetConfExtractCondition,
  WeeklyDailyBudgetConfOutputCondition,
  ColumnLabel,
} from '@/types/entities'
// import { TemplateFile, ResourceType } from '@/types/generated/graphql'
import {
  TemplateFile,
  ResourceType,
  Where,
  WhereOp,
  OrderBy,
  OrderByDirection,
  OrderByNullsOption,
  VUiPWeeklyDailyBudgetConfsCountDocument,
} from '@/types/generated/graphql'
import {
  OUTPUT_COND_DELETE,
  OUTPUT_COND_ITEM,
  OUTPUT_COND_STORE,
  OUTPUT_EXTRACT_TARGET_DEFAULT,
  SELECT_MAX_COUNT,
} from '@/utils/constant'
import { EXPORT_FILENAME } from '@/utils/constant'
import importExportHelper from '@/utils/importExportHelper'
import { setWhereValue, setWhereValues } from '@/utils/searchConditionDef'
import Toast from '@/utils/toast'
const DEFAULT_OUTPUT_CONST = {
  extractTarget: OUTPUT_EXTRACT_TARGET_DEFAULT, // 出力条件（抽出対象）はデフォルト値を設定できるようにしていないため、定数を用意した
  storeOutput: [OUTPUT_COND_STORE.tanten],
  itemOutput: [OUTPUT_COND_ITEM.budgetGroup],
}
export default defineComponent({
  components: { PageView, SettingImportModal },
  layout: 'sidebar',
  created() {
    this.helper = new importExportHelper(this)
  },
  data() {
    return {
      resourceType: ResourceType.UiPWeeklyDailyBudgetConf,
      templateFile: TemplateFile.UiPWeeklyDailyBudgetConf_01,
      extractCondition: {
        itemStore: {
          chubunrui: [],
        },
      },
      outputCondition: DEFAULT_OUTPUT_CONST,
      outputUseList: [
        {
          outputCondType: 'Store',
          displayItems: DEFAULT_OUTPUT_CONST.storeOutput,
          defaultSelected: DEFAULT_OUTPUT_CONST.storeOutput,
          disabledItems: DEFAULT_OUTPUT_CONST.storeOutput,
        },
        {
          outputCondType: 'Item',
          displayItems: DEFAULT_OUTPUT_CONST.itemOutput,
          defaultSelected: DEFAULT_OUTPUT_CONST.itemOutput,
          disabledItems: DEFAULT_OUTPUT_CONST.itemOutput,
        },
      ],
      totalCount: null,
      exportSpinner: false,
    }
  },
  methods: {
    changeExtractCondition(cond: WeeklyDailyBudgetConfExtractCondition) {
      console.log('WeeklyDailyBudgetConfExtractCondition', cond)
      this.extractCondition = cond
      this.requestCount()
    },

    changeOutputCondition(cond: WeeklyDailyBudgetConfOutputCondition) {
      console.log('changeConditionCondition', cond)
      this.outputCondition = cond
      this.requestCount()
    },
    async requestCount() {
      this.exportSpinner = true
      this.totalCount = null
      if (this.checkRequired(false)) {
        try {
          const result = await this.$apollo.query({
            query: VUiPWeeklyDailyBudgetConfsCountDocument,
            variables: { where: this._createWhere() },
          })
          const data = result.data.vUiPWeeklyDailyBudgetConfs.totalCount
          this.totalCount = data.toString()
          this.exportSpinner = false
        } catch (e) {
          console.error(e)
          this.exportSpinner = false
          this.totalCount = '-'
        }
      } else {
        this.exportSpinner = false
        this.totalCount = '-'
      }
    },
    /*********************************
     * 以下はインポートリクエスト用の処理
     *********************************/
    requestImport() {
      console.log('requestImport')
      this.$refs.settingImportModal.show()
    },
    /**
     * インポート開始処理
     */
    async importFileUpload(files: File[]) {
      console.log('importFileUpload', { files })
      this.helper.import({
        files: files,
        resourceType: this.resourceType,
        optionXlsx: { skipRows: 3 },
      })
    },
    /*********************************
     * 以下はエクスポートリクエスト用の処理
     *********************************/
    //　入力チェック
    checkRequired(showToast: boolean) {
      // 入力チェック開始
      let errMsg = []
      if (this.extractCondition.itemStore.chubunrui.length == 0) {
        errMsg.push({
          message: this.$i18n.t('error.requiredItemNotInput'),
        })
      }
      // 選択数上限チェック
      if (this.extractCondition.itemStore.chubunruiCount > SELECT_MAX_COUNT) {
        errMsg.push({
          message: this.$i18n.t('error.itemInputIsTooMany', {
            item: this.$i18n.t('label.chubunrui'),
            selected: this.extractCondition.itemStore.chubunruiCount,
            max: SELECT_MAX_COUNT,
          }),
        })
      }
      if (errMsg.length) {
        if (showToast) {
          for (const msg of errMsg) {
            Toast.error(this, msg)
          }
        }
        // 入力チェックに引っかかった場合はエクスポートの要求を出さない
        return false
      }
      return true
    },
    /**
     * エクスポート処理
     */
    async requestExport() {
      if (this.checkRequired(true)) {
        // this._create_query_string()
        this.helper.export({
          query: this._create_query_string(),
          jmespathQuery:
            'data.vUiPWeeklyDailyBudgetConfs.edges[].node | map(&merge(`{"__update": null}`, @), @)',
          templateFile: this.templateFile,
          filenameExport: EXPORT_FILENAME.weekly_daily_budget_conf,
          columnLabelMapping: this._create_column_label_mapping(),
        })
      }
    },
    _create_query_string() {
      const where = this._createWhere()
      const rowQuery = `
query {
  vUiPWeeklyDailyBudgetConfs(
    where: ${this.helper.generateQueryWhereString(where)}
    orderBy: ${this._createOrderByString()}
  ) {
    edges {
      node {
        inactiveFlg
        vUiPBudgetGroup{
          uiMCategory1{
            categoryName
          }
          uiMCategory2{
            categoryName
          }
          uiMCategory3{
            categoryName
          }
        }
        budgetGroupId
        storeCd
        weeklyBudget
        weight1
        weight2
        weight3
        weight4
        weight5
        weight6
        weight7
        store {
          storeName
          vUiPWeeklyDailyBudgetConf_Weather {
            rain1
            rain2
            rain3
            rain4
            rain5
            rain6
            rain7
          }
        }
        weatherWeightA
        weatherWeightB
        weatherWeightC
        weatherWeightMax
        configuredFlg
        lastModifiedUserName
        lastModifiedDatetime
        saleDate
        vUiPWeeklyDailyBudgetConf_DowIndex {
          sunIndex
          monIndex
          tueIndex
          wedIndex
          thuIndex
          friIndex
          satIndex
        }
      }
    }
  }
}
`
      return this.helper.generateQueryString({
        rowQuery: rowQuery,
        resourceType: this.resourceType,
        where: where,
      })
    },
    _createWhere() {
      const ITEM_SEARCH_CONDITION: Where[] = [
        // 抽出条件
        {
          field: ['vUiPBudgetGroup/category0'],
          op: WhereOp.In,
          value: [''],
        },
        { field: ['configuredFlg'], op: WhereOp.In, value: [''] },
      ]
      const where = JSON.parse(JSON.stringify(ITEM_SEARCH_CONDITION))
      setWhereValue(
        where,
        'vUiPBudgetGroup/category0',
        this.extractCondition.itemStore.chubunrui
      )
      setWhereValue(where, 'configuredFlg', this.outputCondition.extractTarget)
      console.log({ where })
      return where
    },
    _createOrderByString() {
      const orderBy: OrderBy[] = [
        {
          field: 'storeCd',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
        {
          field: 'budgetGroupId',
          direction: OrderByDirection.Asc,
          nullsOption: OrderByNullsOption.NullsLast,
        },
      ]
      console.log({ orderBy })
      return this.helper.generateQueryOrderByString(orderBy)
    },
    _create_column_label_mapping() {
      const columnLabelMapping: ColumnLabel[] = [
        {
          from: '__update',
          to: '',
        },
        {
          from: 'inactiveFlg',
          to: '',
        },
        {
          from: 'vUiPBudgetGroup.uiMCategory1.categoryName',
          to: '',
        },
        {
          from: 'vUiPBudgetGroup.uiMCategory2.categoryName',
          to: '',
        },
        {
          from: 'vUiPBudgetGroup.uiMCategory3.categoryName',
          to: '',
        },
        {
          from: 'budgetGroupId',
          to: '',
        },
        {
          from: 'storeCd',
          to: '',
        },
        {
          from: 'store.storeName',
          to: '',
        },
        {
          from: 'weeklyBudget',
          to: '',
        },
        {
          from: 'vUiPWeeklyDailyBudgetConf_DowIndex.monIndex',
          to: '',
        },
        {
          from: 'vUiPWeeklyDailyBudgetConf_DowIndex.tueIndex',
          to: '',
        },
        {
          from: 'vUiPWeeklyDailyBudgetConf_DowIndex.wedIndex',
          to: '',
        },
        {
          from: 'vUiPWeeklyDailyBudgetConf_DowIndex.thuIndex',
          to: '',
        },
        {
          from: 'vUiPWeeklyDailyBudgetConf_DowIndex.friIndex',
          to: '',
        },
        {
          from: 'vUiPWeeklyDailyBudgetConf_DowIndex.satIndex',
          to: '',
        },
        {
          from: 'vUiPWeeklyDailyBudgetConf_DowIndex.sunIndex',
          to: '',
        },
        {
          from: 'saleDate',
          to: '',
        },
        {
          from: 'weight1',
          to: '',
        },
        {
          from: 'weight2',
          to: '',
        },
        {
          from: 'weight3',
          to: '',
        },
        {
          from: 'weight4',
          to: '',
        },
        {
          from: 'weight5',
          to: '',
        },
        {
          from: 'weight6',
          to: '',
        },
        {
          from: 'weight7',
          to: '',
        },
        {
          from: 'orderBudget1',
          to: '',
        },
        {
          from: 'orderBudget2',
          to: '',
        },
        {
          from: 'orderBudget3',
          to: '',
        },
        {
          from: 'orderBudget4',
          to: '',
        },
        {
          from: 'orderBudget5',
          to: '',
        },
        {
          from: 'orderBudget6',
          to: '',
        },
        {
          from: 'orderBudget7',
          to: '',
        },
        {
          from: 'store.vUiPWeeklyDailyBudgetConf_Weather.rain1',
          to: '',
        },
        {
          from: 'store.vUiPWeeklyDailyBudgetConf_Weather.rain2',
          to: '',
        },
        {
          from: 'store.vUiPWeeklyDailyBudgetConf_Weather.rain3',
          to: '',
        },
        {
          from: 'store.vUiPWeeklyDailyBudgetConf_Weather.rain4',
          to: '',
        },
        {
          from: 'store.vUiPWeeklyDailyBudgetConf_Weather.rain5',
          to: '',
        },
        {
          from: 'store.vUiPWeeklyDailyBudgetConf_Weather.rain6',
          to: '',
        },
        {
          from: 'store.vUiPWeeklyDailyBudgetConf_Weather.rain7',
          to: '',
        },
        {
          from: 'weatherWeight1',
          to: '',
        },
        {
          from: 'weatherWeight2',
          to: '',
        },
        {
          from: 'weatherWeight3',
          to: '',
        },
        {
          from: 'weatherWeight4',
          to: '',
        },
        {
          from: 'weatherWeight5',
          to: '',
        },
        {
          from: 'weatherWeight6',
          to: '',
        },
        {
          from: 'weatherWeight7',
          to: '',
        },
        {
          from: 'weatherWeightA',
          to: '',
        },
        {
          from: 'weatherWeightB',
          to: '',
        },
        {
          from: 'weatherWeightC',
          to: '',
        },
        {
          from: 'weatherWeightMax',
          to: '',
        },
        {
          from: 'configuredFlg',
          to: '',
        },
        {
          from: 'lastModifiedDatetime',
          to: '',
        },
        {
          from: 'lastModifiedUserName',
          to: '',
        },
      ]
      console.log({ columnLabelMapping })
      return columnLabelMapping
    },
  },
})
